






























































































































































































































































































































































.home-app-bar {
    .v-tabs-slider {
        margin: 0 auto;
        max-width: 0;
    }

    .getac-nav-logo {
        max-width: 128px;
        width: 100%;
    }
}
.initial {
    color: rgba(0, 0, 0, 0.54) !important;
}
